import { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import AWSIcon from 'svgs/single-case-study/aws.svg';
import DataAnalysisIcon from 'svgs/single-case-study/data-analysis.svg';
import PythonIcon from 'svgs/single-case-study/python.svg';
import RIcon from 'svgs/single-case-study/r.svg';

import { contentResolver } from '../utilities';

const content = contentResolver({
    project: 'pregnabit',
    brandColor: '--pregnabit-case-study',
    scopeOfWork: [
        {
            title: 'scopeOfWork.dataAnalysis',
            Icon: DataAnalysisIcon,
        },
        {
            title: 'scopeOfWork.r',
            Icon: RIcon,
        },
        {
            title: 'scopeOfWork.python',
            Icon: PythonIcon,
        },
        {
            title: 'scopeOfWork.awsCloud',
            Icon: AWSIcon,
        },
    ],
    projectInfo: {
        count: 2,
        dataIndexes: [0, 2],
    },
    paragraphsCounters: {
        header: 1,
        about: 1,
        challenge: 1,
        mainGoals: 2,
        solutions: 2,
        summary: 3,
    },
    headerWrapperCss: css`
        width: 100%;
    `,
    headerFirstCss: css`
        width: 80%;
    `,
    caseStudies: ['exfluential', 'salesPredictiveAlgorithm', 'churn'],
    caseStudiesSubtitle: 'case-studies.subtitle',
});

export const usePregnabit = () => {
    const images = useStaticQuery(
        graphql`
            query {
                headerImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/laptop.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                aboutImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/about.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                challengeImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/challenge.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                mainGoalsImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/main-goals.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                solutionsImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/solutions.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
                summaryImage: file(
                    relativePath: {
                        eq: "single-case-study/pregnabit/summary.png"
                    }
                ) {
                    ...ImageCaseStudyConfig
                }
            }
        `,
    );

    return {
        data: { ...images, ...content },
    };
};
